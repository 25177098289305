import styled from 'styled-components'

export const StyledWrapper = styled.div`
  max-width: 1300px;
  height: max-content;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  scrollbar-width: none; //hide scrollbar mozilla
  margin-top: 0;

  .products-wrapper {
    margin-bottom: 0 !important;
  }

  .card-wrapper {
    margin: 0;
  }

  .cust_car_icon {
    top: 40%;
  }

  .cust_car_icon.left {
    margin-left: -50px;
  }

  .items {
    margin-top: 0;
    padding: 0px 16px 24px 16px;
    scrollbar-width: none;
  }

  .cta-btn-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cta-btn {
    margin: 20px auto 30px auto;
    width: 80%;
    height: 48px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    background: var(--brand-primary-white-color);
    border: 1px solid var(--brand-primary-black-color);
    border-radius: 12px;
    color: var(--brand-primary-black-color);
    cursor: pointer;
  }

  .cta-label {
    font-family: var(--brand-font-family-text);
    font-style: normal;
    font-weight: var(--brand-font-weight-black);
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
  }

  .font-awesome-icon {
    height: 12px;
    cursor: pointer;
  }

  @media screen and (min-width: 786px) {
    overflow-y: scroll;
    margin: auto;

    .cta-btn {
      margin-top: 30px;
      width: 50%;

      &:hover {
        color: var(--brand-primary-white-color);
        background-color: var(--brand-primary-black-color);

        .chevron-icon path {
          fill: white;
        }
      }
    }

    .carousel-container {
      width: 100% !important;
    }

    .cta-label {
      font-size: 22px;
      line-height: 28px;
    }

    .font-awesome-icon {
      height: 20px;
    }

    .whyThisWorks {
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 18px;
      font-weight: var(--brand-font-weight-normal);

      .why-this-work-item {
        margin-bottom: 10px;
      }

      .key {
        margin-right: 8px;
        color: var(--category-primary-color);
        font-weight: var(--brand-font-weight-bold);
      }
    }
  }
`

export const TitleAndSubtitleContainer = styled.div`
  max-width: 1120px;
  margin: auto;
  padding: 0 16px 20px 16px;
  color: var(--brand-font-default-color);

  @media screen and (min-width: 768px) {
    padding: 0 16px 10px 16px;
  }

  .title {
    margin: 0;
  }

  .main-title {
    padding-bottom: 10px;
  }

  .remove-font-weight {
    font-weight: var(--brand-font-weight-normal);
    h2 {
      padding: 0;
      margin: 0;
      font-size: inherit;
      font-weight: inherit;
    }
  }

  .subtitle {
    color: var(--brand-primary-grey-color);

    @media screen and (min-width: 768px) {
      padding-bottom: 12px;
      font-size: 18px;
    }
  }
`
